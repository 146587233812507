var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _vm.isLoading
        ? _c("Loading")
        : _c(
            "div",
            {
              staticStyle: { height: "100%", width: "100%!important" },
              attrs: { id: "body-content-area" },
            },
            [
              _c(
                "div",
                { attrs: { id: "pagelayout" } },
                [
                  _c("div", { staticClass: "button-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-accent",
                        attrs: {
                          disabled: !_vm.$hasPermissions(
                            _vm.clientSession,
                            ["PERMISSION_TEMPLATES"],
                            2
                          ),
                        },
                        on: { click: _vm.newTemplate },
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", { staticClass: "fas fa-plus-square" }),
                        ]),
                        _c("span", [_vm._v(" Add Template ")]),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button is-dark",
                        attrs: {
                          disabled:
                            _vm.selectedFields.length === 0 ||
                            !_vm.$hasPermissions(
                              _vm.clientSession,
                              ["PERMISSION_TEMPLATES"],
                              2
                            ),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showDeleteModal = true
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", { staticClass: "fas fa-trash" }),
                        ]),
                        _c("span", [
                          _vm._v(
                            " Delete Template" +
                              _vm._s(_vm.selectedFields.length > 1 ? "s" : "") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm.maxHeight > 0
                    ? _c("Grid", {
                        ref: "grid",
                        style: { height: _vm.maxHeight + "px" },
                        attrs: {
                          "data-items": _vm.sortedTemplates,
                          "selected-field": _vm.selectedField,
                          sortable: true,
                          sort: _vm.sort,
                          filterable: false,
                          pageable: _vm.pageable,
                          "page-size": _vm.pageSize,
                          skip: _vm.skip,
                          take: _vm.take,
                          total: _vm.totalRecords,
                          columns: _vm.columns,
                        },
                        on: {
                          pagechange: _vm.pageChangeHandler,
                          sortchange: _vm.sortChangeHandler,
                          selectionchange: _vm.onSelectionChange,
                          headerSelectionchange: _vm.onHeaderSelectionChange,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "name",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "text-is-accent",
                                          attrs: {
                                            to: {
                                              name: "PermissionTemplateEditPage",
                                              params: { id: props.dataItem.id },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.dataItem.name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "updated-at",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .moment(props.dataItem.updated_at)
                                            .format("MM/DD/YYYY h:mm A")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: props.dataItem.selected,
                                          expression: "props.dataItem.selected",
                                        },
                                      ],
                                      staticClass: "k-checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          props.dataItem.selected
                                        )
                                          ? _vm._i(
                                              props.dataItem.selected,
                                              null
                                            ) > -1
                                          : props.dataItem.selected,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleItem(props.dataItem)
                                        },
                                        change: function ($event) {
                                          var $$a = props.dataItem.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  props.dataItem,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  props.dataItem,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              props.dataItem,
                                              "selected",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "checkbox",
                              fn: function () {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.areAllSelected,
                                        expression: "areAllSelected",
                                      },
                                    ],
                                    staticClass: "k-checkbox",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.areAllSelected)
                                        ? _vm._i(_vm.areAllSelected, null) > -1
                                        : _vm.areAllSelected,
                                    },
                                    on: {
                                      click: _vm.toggleSelects,
                                      change: function ($event) {
                                        var $$a = _vm.areAllSelected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.areAllSelected = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.areAllSelected = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.areAllSelected = $$c
                                        }
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3831020409
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
      _vm.showDeleteModal
        ? _c(
            "div",
            { staticClass: "modal is-active", attrs: { id: "delete-modal" } },
            [
              _c("div", {
                staticClass: "modal-background",
                on: {
                  click: function ($event) {
                    _vm.showDeleteModal = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head has-bg-danger" }, [
                  _c("div", { staticClass: "modal-card-title has-bg-danger" }, [
                    _vm._v("Confirm Delete"),
                  ]),
                  _c("a", {
                    staticClass: "delete",
                    attrs: { "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                ]),
                _c("section", { staticClass: "modal-card-body" }, [
                  _c("div", { staticClass: "content" }, [
                    _vm._v(' Type "'),
                    _c("b", [_vm._v("delete")]),
                    _vm._v(
                      '" to confirm that you want to delete ' +
                        _vm._s(
                          _vm.selectedFields.length > 1 ? "these" : "this"
                        ) +
                        " template" +
                        _vm._s(_vm.selectedFields.length > 1 ? "s" : "") +
                        ". "
                    ),
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.confirmationText,
                            expression: "confirmationText",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "type `delete` and then click confirm",
                        },
                        domProps: { value: _vm.confirmationText },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.deleteIfConfirmed.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmationText = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("footer", { staticClass: "modal-card-foot" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger",
                      attrs: { disabled: _vm.confirmationText !== "delete" },
                      on: { click: _vm.deleteIfConfirmed },
                    },
                    [_vm._m(0), _c("span", [_vm._v("Confirm")])]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }